export default {
	"主Key（勿动, 红色自动填充的为重复项，不可有）":"斯洛伐克Slovak (sk)",
	"Alert_PageOneContent":"Celkovo budete mať k dispozícii 2 profily pre rôzne situácie, napríklad pre schôdzku alebo iné časové úseky dňa pre optimálne nastavenie.",
	"Alert_PageTwoContent1":"Ťuknutím upravte názov profilu",
	"Alert_PageTwoContent2":"Ťuknutím na ikony pod „Opakovať“ nastavíte dni účinnosti tohto profilu, opätovným ťuknutím výber zrušíte.",
	"Alert_PageThreeContent1":"„Celý deň“ znamená, že vaše nastavenia budú účinné počas celého dňa.",
	"Alert_PageThreeContent2":"Po vypnutí je možné upozornenia upraviť podľa denného a nočného času",
	"Alert_PageThreeContent3":"Ťuknutím na šípku \">\" zmeníte obdobie upozornenia pre dennú a nočnú dobu.",
	"Alert_PageFourContent1":"Urgentné upozornenie nízkej hladiny môžete vypnúť pomocou prepínačov.",
	"Alert_PageFourContent2":"Úroveň upozornení sa nedá zmeniť. Aplikácia vás upozorní, keď sú hodnoty glukózy nižšie ako nastavenia.",
	"Alert_PageFourContent3":"Keď trvá pomerne dlhé obdobie urgentne nízkej hodnoty, aplikácia vás bude upozorňovať v nastavených intervaloch.",
	"Alert_PageFourContent4":"Zvuky upozornení si môžete prispôsobiť pomocou rôznych vyzváňacích tónov alebo vibrácií.",
	"Alert_PageFiveContent1":"Upozornenie na nízku hladinu môžete vypnúť prepínačom.",
	"Alert_PageFiveContent2":"Úroveň upozornení je možné zmeniť; aplikácia vás upozorní, keď sú hodnoty glukózy nižšie ako nastavenia.",
	"Alert_PageFiveContent3":"Môžete nastaviť interval upozornení. Keď trvá pomerne dlhé obdobie nízkej hodnoty, aplikácia vás bude upozorňovať v nastavených intervaloch.",
	"Alert_PageFiveContent4":"Zvuky upozornení si môžete prispôsobiť pomocou rôznych vyzváňacích tónov alebo vibrácií.",
	"Alert_PageSixContent1":"Upozornenie na vysokú hladinu môžete vypnúť prepínačom.",
	"Alert_PageSixContent2":"Úroveň upozornení je možné zmeniť; aplikácia vás upozorní, keď sú hodnoty glukózy vyššie ako nastavenia.",
	"Alert_PageSixContent3":"Môžete nastaviť interval pre upozornenia. Keď trvá pomerne dlhé obdobie vysokej hodnoty, aplikácia vás bude upozorňovať v nastavených intervaloch.",
	"Alert_PageSixContent4":"Svoje prvé upozornenie na vysokú hladinu môžete manuálne odložiť o určité obdobie. Ak vaša hladina glukózy zostáva v rozsahu vysokej výstrahy; upozornenia sa obnovia. Napríklad aplikácia spustí upozornenia 20 minút po prvej vysokej udalosti.",
	"Alert_PageSixContent5":"Zvuky upozornení si môžete prispôsobiť aj pomocou rôznych vyzváňacích tónov alebo vibrácií.",
	"Alert_PageSevenContent1":"Ťuknutím na „Pridať profil upozornení“ vytvoríte nové nastavenie, ktoré bude platiť v rôznych obdobiach. Upozorňujeme, že ak vyberiete prekrývajúce sa dni, ktoré sú zahrnuté v inom profile, nové nastavenia prepíšu existujúce pre príslušné dni.",
	"Alert_PageSevenContent2":"Vytvorí sa nový profil, môžete prispôsobiť obdobia účinnosti 2 profilov.",
	"Alert_Setting_Model":"Zvuky upozornení",
	"Alert_Setting_DelayFirst":"Oneskorenie prvého vysokého upozornenia",
	"Alert_Setting_Mg_54":"55 mg/dL",
	"Alert_AlertTitle":"Prispôsobte si upozornenia na glukózu",
	"Alert_UrgentLowAlert":"Naliehavé upozornenie na nízku hladinu",
	"Alert_LowAlert":"Upozornenie na nízku hladinu",
	"Alert_HighAlert":"Upozornenie na vysokú hladinu",
	"Monday_Abb":"Po ",
	"Tuesday_Abb":"Ut",
	"Wednesday_Abb":"Str",
	"Thursday_Abb":"Št",
	"Friday_Abb":"Pia",
	"Saturday_Abb":"So",
	"Sunday_Abb":"Ne",
	"Alert_Setting_AllDay":"Celý deň",
	"Alert_Setting_UrgentLow":"Naliehavé upozornenie na nízku hladinu",
	"Alert_Setting_Low":"Upozornenie na nízku hladinu",
	"Alert_Setting_High":"Upozornenie na vysokú hladinu",
	"Alert_Setting_Daytime":"Počas dňa (08:00-20:00)",
	"Alert_Setting_Nighttime":"Počas noci(20:00-08:00)",
	"Alert_Setting_Interval":"Interval",
	"Alert_Setting_Level":"Úroveň",
	"Alert_Setting_Vibration":"Vibrácie",
	"Alert_Setting_Add":"Pridať profil upozornenia",
	"Alert_Setting_System":"Systém",
	"Alert_Setting_Mg_180":"180 mg/dl",
	"Alert_Setting_Mg_70":"70 mg/dl",
	"Alert_Setting_Mmol_10":"10,0 mmol/l",
	"Alert_Setting_Mmol_3":"3,1 mmol/l",
	"Alert_Setting_Mmol_3_9":"3,9 mmol/l",
	"Alert_Setting_Default":"Predvolené nastavenie",
	"Alert_Setting_Default2":"Predvolené nastavenie2",
	"Alert_Setting_Min_5":"5 minút",
	"Alert_Setting_Min_30":"30 minút",
	"Alert_Setting_Repeat":"Opakovať",
	"FAQ_FaqTitle1":"Čo je aplikácia Syai Link?",
	"FAQ_FaqTitle2":"Aký je rozdiel medzi aplikáciami Syai Link a Syai Tag?",
	"FAQ_FaqTitle3":"Mám robiť rozhodnutia o liečbe na základe aplikácie Syai Link?",
	"FAQ_FaqTitle4":"Ako funguje aplikácia Syai Link?",
	"FAQ_FaqTitle5":"Budú údaje Syai Link oneskorené? Aké dlhé je toto oneskorenie?",
	"FAQ_FaqTitle6":"Aké upozornenia budem dostávať?",
	"FAQ_FaqTitle7":"Koľko používateľov Syai Tag môžem sledovať pomocou aplikácie Syai Link?",
	"FAQ_FaqTitle8":"Keď nadviažem spojenie s viacerými zdieľajúcimi používateľmi, čie informácie sa zobrazia na mojom widgete?",
	"FAQ_FaqTitle9":"Môžem vidieť historické údaje zdieľajúceho používateľa?",
	"FAQ_FaqTitle10":"Čo znamená „HI“ a „LO“?",
	"FAQ_FaqTitle11":"Čo znamená modrá oblasť v grafe trendu?",
	"FAQ_FaqTitle12":"Čo má cieľový rozsah spoločné s upozorneniami na hladinu cukru v krvi?",
	"FAQ_FaqTitle13":"Môžem prostredníctvom svojho konta Syai Link pristupovať k iným aplikáciám a službám?",
	"FAQ_FaqTitle14":"Aké informácie sa budú zobrazovať v centre správ?",
	"FAQ_FaqContent1":"Aplikácia Syai Link je aplikácia špeciálne navrhnutá pre príbuzných, priateľov a ostatných používateľov Syai Tag. Môžete v nej vidieť údaje o hladine cukru v krvi v reálnom čase, krivku cukru v krvi a stav senzora používateľov Syai Tag a dostávať oznámenia v prípade abnormálnej hladiny cukru v krvi a iných núdzových situácií.",
	"FAQ_FaqContent2":"Aplikácia Syai Link je navrhnutá tak, aby ju mohla používať rodina, priatelia a opatrovatelia. Tí môžu na svojich smartfónoch vidieť údaje o glukóze a upozornenia v reálnom čase. Aplikácia Syai Tag je navrhnutá tak, aby ju ľudia s cukrovkou mohli používať na bežné monitorovanie glukózy pomocou smartfónu a senzora Syai Tag.",
	"FAQ_FaqContent3":"Údaje o glukóze a analýzy poskytované v aplikácii by sa nemali používať ako priame odporúčania na liečbu. Pred prijatím akýchkoľvek zásadných rozhodnutí o liečbe vykonajte ďalšie vyšetrenia na dvojitú kontrolu, ako sú napríklad testy z prsta, aby ste dosiahli optimálnu liečbu.",
	"FAQ_FaqContent4":"Keď aplikácia Syai Tag prijme údaje o glukóze zo senzora, odošle ich na server. Aplikácia Syai Tag App umožňuje používateľovi pozvať rodinu, priateľov a osoby, ktoré sa o neho starajú, aby sledovali stav glukózy zdieľaného používateľa. Táto rodina, priatelia a opatrovatelia si môžu do svojich telefónov nainštalovať aplikáciu Syai Link, prijať pozvánku na zdieľanie a potom si prezerať údaje o glukóze a dostávať upozornenia o glukóze.",
	"FAQ_FaqContent5":"Oneskorenie prenosu údajov je spôsobené rôznymi faktormi, napríklad oneskorením siete, prerušeným spojením atď. V relatívne ideálnych podmienkach by malo byť oneskorenie menšie ako niekoľko sekúnd.",
	"FAQ_FaqContent6":"a. Keď je hodnota glukózy u pacienta vyššia/v rozsahu/nižšia ako nastavené úrovne upozornení, napríklad vysoká, nízka a urgentne nízka hladina glukózy.\nb. Keď je hladina glukózy u zdieľaného pacienta mimo nastavených rozsahov, t. j. pod %n alebo nad %r.\nc. Keď zdieľajúca osoba ukončila svoju reláciu.\nd. Keď je účet zdieľajúceho vymazaný.\ne. Keď zdieľajúci ukončil zdieľanie.\nf. Keď existujú neprečítané správy.\ng. Keď sú nové pozvánky.",
	"FAQ_FaqContent7":"Účet Syai Link môže sledovať až 10 používateľov Syai Tag.\nÚčet Syai Tag môže pozvať až 10 používateľov Syai Link.",
	"FAQ_FaqContent8":"Vzhľadom na obmedzenia funkčnosti widgetu môžeme zobraziť iba informácie o prvom zdieľajúcom vo vašom zozname. Na uprednostnenie preferovaného zdieľateľa môžete použiť funkciu „ Pin on Top (Pripnúť na vrch)“ na stránke s nastaveniami.",
	"FAQ_FaqContent9":"Sledovateľ si môže prezerať údaje v reálnom čase a údaje za posledných 24 hodín, ale nie údaje pred tým. Okrem toho je možné zobraziť údaje až po prijatí pozvánky, údaje pred týmto dátumom nie je možné zobraziť.",
	"FAQ_FaqContent10":"„HI“ označuje hodnotu glukózy vyššiu ako %r.\n ‚LO‘ označuje hodnotu glukózy nižšiu ako %n.",
	"FAQ_FaqContent11":"To predstavuje cieľový rozsah nastavený používateľom v aplikácii Syai Tag a vy toto nastavenie nemôžete zmeniť.",
	"FAQ_FaqContent12":"Obe sú nezávislé a navzájom sa neovplyvňujú. Cieľový rozsah sa zobrazí len v grafe trendov, ktorý nastaví používateľ v aplikácii Syai Tag a nie je možné ho upraviť v aplikácii Syai Link. \nUpozornenie na glukózu je upozornenie, ktoré sa vám odošle, keď hladina glukózy zdieľanej osoby prekročí normálny rozsah. Toto upozornenie je možné upraviť v časti Nastavenia.",
	"FAQ_FaqContent13":"Váš účet Syai Link je vlastne účtom Syai, môžete mať prístup ku všetkým aplikáciám a službám na neprofesionálne použitie, ako je napríklad aplikácia Syai Tag.",
	"FAQ_FaqContent14":"Všetky prijaté upozornenia sa zobrazia v centre správ. V centre správ môžete skontrolovať aj nasledujúce správy s úlohami:\na. Čakajúce pozvánky.\nb. Keď je k dispozícii nová aktualizácia.\nc. Upozornenia na vysokú/nízku/naliehavo nízku hodnotu glukózy."
}