export default {
	"主Key（勿动, 红色自动填充的为重复项，不可有）":"斯洛文尼亚Slovenia(si)",
	"Alert_PageOneContent":"Na voljo boste imeli skupno 2 profila za različne scenarije, kot je sestanek ali druga časovna obdobja v dnevu za optimalne izkušnje.",
	"Alert_PageTwoContent1":"Tapnite za urejanje imena profila",
	"Alert_PageTwoContent2":"Tapnite ikone pod »Ponovi«, da nastavite veljavne dneve tega profila, tapnite znova, da prekličete izbiro.",
	"Alert_PageThreeContent1":"»Ves dan« pomeni, da bodo vaše nastavitve veljale ves dan.",
	"Alert_PageThreeContent2":"Ko so opozorila izklopljena, jih je mogoče spreminjati glede na dan in noč",
	"Alert_PageThreeContent3":"Tapnite puščico »>«, da spremenite obdobje opozarjanja za podnevi in ponoči.",
	"Alert_PageFourContent1":"Opozorilo o nujno nizki vrednosti lahko izklopite s preklopnim stikalom.",
	"Alert_PageFourContent2":"Stopnje opozorila ni mogoče spremeniti. Aplikacija vas bo obvestila, ko bodo odčitki glukoze nižji od nastavitev.",
	"Alert_PageFourContent3":"Ko poteka razmeroma dolgo obdobje nujno nizke vrednosti odčitkov, vas bo aplikacija obveščala po nastavljenih intervalih.",
	"Alert_PageFourContent4":"Zvoke opozoril lahko prilagodite z različnimi melodijami zvonjenja ali vibriranjem.",
	"Alert_PageFiveContent1":"Opozorilo o nizki vrednosti lahko izklopite s preklopnim stikalom.",
	"Alert_PageFiveContent2":"Stopnjo opozorila je mogoče spremeniti; aplikacija vas bo obvestila, ko bodo odčitki glukoze nižji od nastavitev.",
	"Alert_PageFiveContent3":"Nastavite lahko interval za opozorila. V primeru razmeroma dolgega obdobja nizkih vrednosti odčitkov vas bo aplikacija o tem obveščala v nastavljenih intervalih.",
	"Alert_PageFiveContent4":"Opozorilne zvoke lahko prilagodite z različnimi melodijami zvonjenja ali vibriranjem.",
	"Alert_PageSixContent1":"Opozorilo o visoki vrednosti lahko izklopite s preklopnim stikalom.",
	"Alert_PageSixContent2":"Stopnjo opozorila je mogoče spremeniti; aplikacija vas bo obvestila, ko bodo odčitki glukoze višji od nastavitev.",
	"Alert_PageSixContent3":"Nastavite lahko interval za opozorila. V primeru razmeroma dolgega obdobja visokih vrednosti odčitkov vas bo aplikacija o tem obveščala v nastavljenih intervalih.",
	"Alert_PageSixContent4":"Prvo opozorilo o visoki vrednosti lahko ročno zamaknete za določeno obdobje.Če vaša raven glukoze ostane v območju opozorila za visoko vrednost, se bodo opozorilna obvestila nadaljevala.Aplikacija bo na primer sprožila opozorila 20 minut po prvem dogodku visoke vrednosti.",
	"Alert_PageSixContent5":"Opozorilne zvoke lahko prilagodite tudi z različnimi melodijami zvonjenja ali vibriranjem.",
	"Alert_PageSevenContent1":"Tapnite »Dodaj profil opozoril«, da ustvarite novo nastavitev, ki bo veljala v različnih obdobjih. Upoštevajte, da če izberete prekrivajoče se dneve, ki so vključeni v drug profil, bodo nove nastavitve prepisale obstoječe za zadevne dneve.",
	"Alert_PageSevenContent2":"Ustvari se nov profil, lahko prilagodite obdobja veljavnosti obeh profilov.",
	"Alert_Setting_Model":"Opozorilni zvoki",
	"Alert_Setting_DelayFirst":"Zamakni prvo opozorilo za visoko vrednost",
	"Alert_Setting_Mg_54":"3,1 mmol/l",
	"Alert_AlertTitle":"Prilagodite opozorila za vrednosti glukoze",
	"Alert_UrgentLowAlert":"Opozorilo o nujno nizki vrednosti",
	"Alert_LowAlert":"Opozorilo o nizki vrednosti ",
	"Alert_HighAlert":"Opozorilo o visoki vrednosti ",
	"Monday_Abb":"Pon",
	"Tuesday_Abb":"Tor",
	"Wednesday_Abb":"Sre",
	"Thursday_Abb":"Čet",
	"Friday_Abb":"Pet",
	"Saturday_Abb":"Sob",
	"Sunday_Abb":"Ned",
	"Alert_Setting_AllDay":"Cel dan",
	"Alert_Setting_UrgentLow":"Opozorilo o alarmantno nizki vrednosti",
	"Alert_Setting_Low":"Opozorilo o nizki vrednosti",
	"Alert_Setting_High":"Opozorilo o visoki vrednosti",
	"Alert_Setting_Daytime":"Podnevi (8:00–20:00)",
	"Alert_Setting_Nighttime":"Ponoči (20:00–8:00)",
	"Alert_Setting_Interval":"Interval",
	"Alert_Setting_Level":"Raven",
	"Alert_Setting_Vibration":"Vibracije",
	"Alert_Setting_Add":"Dodaj profil opozoril",
	"Alert_Setting_System":"Sistem",
	"Alert_Setting_Mg_180":"10,0 mmol/l",
	"Alert_Setting_Mg_70":"3,9 mmol/l",
	"Alert_Setting_Mmol_10":"10,0 mmol/l",
	"Alert_Setting_Mmol_3":"3,1 mmol/l",
	"Alert_Setting_Mmol_3_9":"3,9 mmol/l",
	"Alert_Setting_Default":"Privzeto",
	"Alert_Setting_Default2":"Privzeto2",
	"Alert_Setting_Min_5":"5 min",
	"Alert_Setting_Min_30":"30 min",
	"Alert_Setting_Repeat":"Ponovi",
	"FAQ_FaqTitle1":"Kaj je aplikacija Syai Link?",
	"FAQ_FaqTitle2":"Kakšna je razlika med aplikacijama Syai Link in Syai Tag?",
	"FAQ_FaqTitle3":"Ali naj sprejemam odločitve o zdravljenju na podlagi aplikacije Syai Link?",
	"FAQ_FaqTitle4":"Ali naj sprejemam odločitve o zdravljenju na podlagi aplikacije Syai Doctor?",
	"FAQ_FaqTitle5":"Kako deluje aplikacija Syai Doctor?",
	"FAQ_FaqTitle6":"Katera obvestila lahko prejemam?",
	"FAQ_FaqTitle7":"Koliko uporabnikov aplikacije Syai Tag lahko spremljam z aplikacijo Syai Link?",
	"FAQ_FaqTitle8":"Ko vzpostavim povezavo z več uporabniki v skupni rabi, čigave informacije bo prikazal moj pripomoček?",
	"FAQ_FaqTitle9":"Ali lahko vidim zgodovinske podatke uporabnika v skupni rabi?",
	"FAQ_FaqTitle10":"Kaj pomenita »HI« in »LO«?",
	"FAQ_FaqTitle11":"Kaj označuje modro območje na grafu trendov?",
	"FAQ_FaqTitle12":"Kakšno zvezo ima ciljno območje z opozorili o krvnem sladkorju?",
	"FAQ_FaqTitle13":"Lahko s svojim računom Syai Link dostopam do drugih aplikacij in storitev?",
	"FAQ_FaqTitle14":"Katere informacije bodo prikazane v centru za sporočila?",
	"FAQ_FaqContent1":"Aplikacija Syai Link je zasnovana posebej za sorodnike, prijatelje in druge uporabnike aplikacije Syai Tag. Ogledate si lahko podatke o krvnem sladkorju v realnem času, krivuljo krvnega sladkorja in spremljate stanje uporabnikov aplikacije Syai Tag ter prejemate obvestila v primeru nenormalnega krvnega sladkorja in drugih nujnih primerih.",
	"FAQ_FaqContent2":"Aplikacija Syai Link je zasnovana za uporabo družinskih članov, prijateljev in negovalcev, ki lahko na svojih pametnih telefonih spremljajo podatke o glukozi in opozorila v realnem času. \nAplikacija Syai Tag je zasnovana za ljudi s sladkorno boleznijo, da jo uporabljajo za rutinsko spremljanje glukoze s pametnim telefonom in senzorjem Syai Tag.",
	"FAQ_FaqContent3":"Podatkov o glukozi in analitičnih podatkov, ki so na voljo v aplikaciji, ne smete uporabljati kot neposredno napotitev na zdravljenje.Preden sprejmete kakršne koli ključne odločitve o zdravljenju, preudarno premislite in po potrebi opravite druge preglede, kot so testi s prstnim odvzemom, da potrdite podatke in izberete optimalno zdravljenje.",
	"FAQ_FaqContent4":"Ko aplikacija Syai Tag od senzorja prejme podatke o glukozi, jih posreduje strežniku.Aplikacija Syai Tag uporabniku omogoča, da povabi zdravnike in ponudnike zdravstvenih storitev k spremljanju podatkov o glukozi.Zdravniki lahko sprejmejo vabila v aplikaciji Syai Doctor in po potrebi dostopajo do podatkov o bolnikih.",
	"FAQ_FaqContent5":"Zakasnitev podatkov lahko povzročijo različni dejavniki, kot so zamude v omrežju, prekinjene povezave in drugi. V razmeroma idealnih pogojih bi naj bil zamik krajši od nekaj sekund.",
	"FAQ_FaqContent6":"a. Ko je odčitek glukoze pri bolniku nad/na/pod nastavljenimi opozorilnimi ravnmi, kot so visoka, nizka in nujno nizka raven glukoze.\nb. Ko je odčitek glukoze pri bolniku izven prednastavljenih območij, to je pod %n ali nad %r.\nc. Ko delitelj konča svojo sejo.\nd. Ko je račun delitelja izbrisan.\ne. Ko delitelj preneha deliti.\nf. Ko obstajajo neprebrana sporočila.\ng. Ko so na voljo nova vabila.",
	"FAQ_FaqContent7":"Vsak račun Syai Link lahko sledi do 10 uporabnikom aplikacije Syai Tag.\nVsak račun Syai Tag lahko povabi do 10 uporabnikov aplikacije Syai Link.",
	"FAQ_FaqContent8":"Zaradi omejitev v funkcionalnosti gradnika lahko prikažemo le podatke prvega delitelja na vašem seznamu. Uporabite lahko funkcijo »Pripni na vrh« na strani z nastavitvami, da določite prednost izbranega delitelja.",
	"FAQ_FaqContent9":"Sledilec si lahko ogleda podatke v realnem času in podatke iz zadnjih 24 ur, ne pa tudi podatkov pred tem. Poleg tega lahko podatke vidite šele, ko sprejmete vabilo; podatkov pred tem ni mogoče videti.",
	"FAQ_FaqContent10":"»HI« označuje odčitek glukoze, višji od %r.\n»LO« označuje odčitek glukoze, nižji od %n.",
	"FAQ_FaqContent11":"To predstavlja ciljno območje, ki ga je delitelj nastavil v svoji aplikaciji Syai Tag in te nastavitve ne morete spremeniti.",
	"FAQ_FaqContent12":"Obe vrednosti sta neodvisni in ne vplivata ena na drugo. Ciljno območje bo prikazano samo na grafu trenda, ki ga nastavi delitelj v svoji aplikaciji Syai Tag, in ga ni mogoče spremeniti v aplikaciji Syai Link.\nOpozorilo o glukozi je obvestilo, ki se vam pošlje, ko raven glukoze delitelja preseže normalno območje. To je mogoče spremeniti na strani z nastavitvami.",
	"FAQ_FaqContent13":"Vaš račun Syai Link je pravzaprav račun Syai in z njim lahko dostopate do vseh aplikacij in storitev za nepoklicno uporabo, kot je aplikacija Syai Tag.",
	"FAQ_FaqContent14":"Vsa obvestila, ki jih prejmete, bodo prikazana v centru za sporočila.V centru za sporočila lahko upravljate tudi naslednja sporočila opravil:\na. Čakajoča vabila.\nb.Nova posodobitve.\nc.Opozorila o glukozi za visoke/nizke/nujno nizke vrednosti odčitkov."
}