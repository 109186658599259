export default {
	"主Key（勿动, 红色自动填充的为重复项，不可有）":"捷克Czech(cs)",
	"Alert_PageOneContent":"K dispozici budete mít celkem 2 profily pro různé situace, jako je schůzka nebo jiné denní doby pro optimální nastavení.",
	"Alert_PageTwoContent1":"Kliknutím upravte název profilu",
	"Alert_PageTwoContent2":"Kliknutím na ikony pod „Opakovat“ nastavíte dny účinnosti tohoto profilu, dalším kliknutím výběr zrušíte.",
	"Alert_PageThreeContent1":"„Celý den“ znamená, že vaše nastavení bude platit po celý den.",
	"Alert_PageThreeContent2":"Po vypnutí lze upozornění upravit podle denní a noční doby.",
	"Alert_PageThreeContent3":"Kliknutím na šipku \">\" změníte dobu upozornění pro den a noc.",
	"Alert_PageFourContent1":"Upozornění na urgentně nízkou hladinu můžete vypnout pomocí přepínačů.",
	"Alert_PageFourContent2":"Úroveň upozornění nelze změnit. Aplikace vás upozorní, když budou hodnoty glukózy nižší než je nastaveno.",
	"Alert_PageFourContent3":"Když probíhá relativně dlouhou dobu Urgentně nízká hladina, aplikace vás upozorní v nastavených intervalech.",
	"Alert_PageFourContent4":"Zvuky upozornění si můžete přizpůsobit pomocí různých vyzváněcích tónů nebo vibrací.",
	"Alert_PageFiveContent1":"Upozornění na nízkou hladinu můžete vypnout přepínačem.",
	"Alert_PageFiveContent2":"Úroveň upozornění lze změnit; aplikace vás upozorní, když budou hodnoty glukózy nižší než je nastaveno.",
	"Alert_PageFiveContent3":"Můžete nastavit interval upozornění. Když je relativně dlouhou dobu nízká hladina, aplikace vás bude upozorňovat v nastavených intervalech.",
	"Alert_PageFiveContent4":"Zvuky upozornění si můžete přizpůsobit pomocí různých vyzváněcích tónů nebo vibrací.",
	"Alert_PageSixContent1":"Upozornění na vysokou hladinu si můžete vypnout přepínačem.",
	"Alert_PageSixContent2":"Úroveň upozornění lze změnit; aplikace vás upozorní, když jsou hodnoty glukózy vyšší než nastavené.",
	"Alert_PageSixContent3":"Můžete nastavit interval pro upozornění. Pokud je relativně dlouhou dobu vysoká hladina, aplikace vás bude upozorňovat v nastavených intervalech.",
	"Alert_PageSixContent4":"Své první upozornění na vysokou hladinu můžete ručně odložit na určitou dobu. Pokud vaše hladina glukózy zůstává v rozmezí upozornění na vysokou hladinu; upozornění budou pokračovat. Například aplikace spustí upozornění 20 minut po první vysoké hladině.",
	"Alert_PageSixContent5":"Zvuky upozornění si můžete přizpůsobit také pomocí různých vyzváněcích tónů nebo vibrací.",
	"Alert_PageSevenContent1":"Kliknutím na „Přidat profil upozornění“ vytvoříte nové nastavení, které bude platit v různých obdobích. Všimněte si, že pokud vyberete překrývající se dny, které jsou zahrnuty v jiném profilu, nové nastavení přepíše stávající nastavení pro příslušné dny.",
	"Alert_PageSevenContent2":"Vytvoří se nový profil, můžete upravit dobu platnosti 2 profilů.",
	"Alert_Setting_Model":"Zvuky upozornění",
	"Alert_Setting_DelayFirst":"Zpoždění prvního upozornění na vysokou hladinu",
	"Alert_Setting_Mg_54":"55 mg/dL",
	"Alert_AlertTitle":"Přizpůsobte si upozornění na glukózu",
	"Alert_UrgentLowAlert":"Naléhavé upozornění na nízkou hladinu",
	"Alert_LowAlert":"Upozornění na nízkou hladinu",
	"Alert_HighAlert":"Upozornění na vysokou hladinu",
	"Monday_Abb":"Po",
	"Tuesday_Abb":"Út",
	"Wednesday_Abb":"St",
	"Thursday_Abb":"Čt",
	"Friday_Abb":"Pá",
	"Saturday_Abb":"So",
	"Sunday_Abb":"Ne",
	"Alert_Setting_AllDay":"Celý den",
	"Alert_Setting_UrgentLow":"Upozornění na urgentně nízkou hladinu",
	"Alert_Setting_Low":"Upozornění na nízkou hladinu",
	"Alert_Setting_High":"Upozornění na vysokou hladinu",
	"Alert_Setting_Daytime":"Den (08:00-20:00)",
	"Alert_Setting_Nighttime":"Noc (20:00-08:00)",
	"Alert_Setting_Interval":"Interval",
	"Alert_Setting_Level":"Úroveň",
	"Alert_Setting_Vibration":"Vibrace",
	"Alert_Setting_Add":"Přidat profil upozornění",
	"Alert_Setting_System":"Systém",
	"Alert_Setting_Mg_180":"180 mg/dL",
	"Alert_Setting_Mg_70":"70 mg/dL",
	"Alert_Setting_Mmol_10":"10.0 mmol/L",
	"Alert_Setting_Mmol_3":"3.1 mmol/L",
	"Alert_Setting_Mmol_3_9":"3.9 mmol/L",
	"Alert_Setting_Default":"Výchozí hodnota",
	"Alert_Setting_Default2":"Výchozí hodnota2",
	"Alert_Setting_Min_5":"5 minut",
	"Alert_Setting_Min_30":"30 minut",
	"Alert_Setting_Repeat":"Opakovat",
	"FAQ_FaqTitle1":"Co je aplikace Syai Link?",
	"FAQ_FaqTitle2":"Jaký je rozdíl mezi Syai Link a Syai Tag?",
	"FAQ_FaqTitle3":"Mám činit rozhodnutí o léčbě na základě aplikace Syai Link?",
	"FAQ_FaqTitle4":"Jak funguje aplikace Syai Link?",
	"FAQ_FaqTitle5":"Budou údaje aplikace Syai Link zpožděny? Jak dlouhé je zpoždění?",
	"FAQ_FaqTitle6":"Jaká oznámení budu dostávat?",
	"FAQ_FaqTitle7":"Kolik uživatelů Syai Tag mohu pomocí aplikace Syai Link sledovat?",
	"FAQ_FaqTitle8":"Když navážu spojení s více sdílejícími, čí informace se budou na mém widgetu zobrazovat?",
	"FAQ_FaqTitle9":"Mohu zobrazit historické údaje sdílejícího?",
	"FAQ_FaqTitle10":"Co znamená „HI“ a „LO“?",
	"FAQ_FaqTitle11":"Co znamená modrá oblast v grafu trendu?",
	"FAQ_FaqTitle12":"Co má rozmezí cílových hodnot společného s upozorněními na hladinu cukru v krvi?",
	"FAQ_FaqTitle13":"Mohu se svým účtem Syai Link přistupovat k jiným aplikacím nebo službám?",
	"FAQ_FaqTitle14":"Jaké informace se budou zobrazovat v centru zpráv?",
	"FAQ_FaqContent1":"Aplikace Syai Link je aplikace speciálně navržená pro příbuzné, přátele a další uživatele Syai Tag. Můžete v ní vidět údaje o krevním cukru v reálném čase, křivku krevního cukru a stav senzoru uživatelů Syai Tag a přijímat upozornění v případě abnormální hladiny krevního cukru a dalších mimořádných událostí.",
	"FAQ_FaqContent2":"Aplikace Syai Link je navržena tak, aby ji mohla používat rodina, přátelé a pečující osoby. Ti mohou na svých chytrých telefonech sledovat údaje o glukóze v reálném čase a upozornění. \nAplikace Syai Tag je navržena tak, aby ji mohli používat lidé s diabetem k běžnému monitorování glukózy pomocí chytrého telefonu a senzoru Syai Tag.",
	"FAQ_FaqContent3":"Údaje o glukóze a analýzy poskytované v aplikaci by neměly sloužit jako přímé doporučení k léčbě. Před zásadními rozhodnutími o léčbě proveďte další vyšetření pro překontrolování, například vyšetření z prstu, abyste dosáhli optimálního výsledku léčby.",
	"FAQ_FaqContent4":"Když aplikace Syai Tag obdrží údaje o glukóze ze senzoru, předá je na server. Aplikace Syai Tag umožňuje uživateli pozvat rodinu, přátele a pečující osoby, aby sledovali stav glukózy sdíleného uživatele. Tito rodinní příslušníci, přátelé a pečující osoby si mohou do svých telefonů nainstalovat aplikaci Syai Link, přijmout pozvání ke sdílení a poté si prohlížet údaje o glukóze a přijímat upozornění na glukózu.",
	"FAQ_FaqContent5":"Zpoždění přenosu údajů je způsobeno různými faktory, jako je pomalá síť, přerušované připojení apod. Za relativně ideálních podmínek by zpoždění mělo být menší než několik sekund.",
	"FAQ_FaqContent6":"a. Když je hodnota glukózy pacienta vyšší/normální/nižší než nastavené úrovně upozornění, například vysoká, nízká a urgentně nízká hladina glukózy.\nb. Když je hladina glukózy sdíleného pacienta mimo nastavené rozmezí, tj. pod %n nebo nad %r.\nc. Když sdílející osoba ukončila relaci.\nd. Když je účet sdílejícího smazán.\ne. Když sdílející ukončil sdílení.\nf. Když existují nepřečtené zprávy.\ng. Když jsou nové pozvánky.",
	"FAQ_FaqContent7":"Účet Syai Link může sledovat až 10 uživatelů Syai Tag.\nÚčet Syai Tag může pozvat až 10 uživatelů Syai Link.",
	"FAQ_FaqContent8":"Kvůli omezení funkčnosti widgetu můžeme zobrazit pouze informace prvního sdílejícího na vašem seznamu. K upřednostnění preferovaného sdílejícího můžete použít funkci „Topovat“ v nastavení.",
	"FAQ_FaqContent9":"Sledující může zobrazit údaje v reálném čase a údaje za posledních 24 hodin, ale ne starší. Údaje navíc můžete vidět až po přijetí pozvánky, před tímto datemnelze prohlížet údaje.",
	"FAQ_FaqContent10":"\"HI\" označuje hodnotu glukózy vyšší než %r.\n\"LO\" označuje hodnotu glukózy nižší než %n.",
	"FAQ_FaqContent11":"To představuje rozmezí cílových hodnot nastavené sdílejícím v jeho aplikaci Syai Tag a toto nastavení nemůžete měnit.",
	"FAQ_FaqContent12":"Obě hodnoty jsou nezávislé a navzájem se neovlivňují. Rozmezí cílových hodnot se zobrazí pouze v grafu trendů, který nastavil sdílející v aplikaci Syai Tag a nelze ho v aplikaci Syai Link upravit. \nUpozornění na glukózu je oznámení, které vám bude zasláno, když hladina glukózy sdílejícího překročí normální rozmezí. Toto upozornění lze upravit v Nastavení.",
	"FAQ_FaqContent13":"Částečně je váš účet Syai Link ve skutečnosti účtem Syai, můžete přistupovat ke všem aplikacím a službám pro neprofesionální použití, jako je aplikace Syai Tag.",
	"FAQ_FaqContent14":"Všechna oznámení, která obdržíte, se zobrazí v centru zpráv. V centru zpráv můžete také zkontrolovat následující úkoly:\na. Čekající pozvánky.\nb. Když je k dispozici nová aktualizace.\nc. Upozornění na vysokou/ nízkou/ urgentně nízkou hladinu glukózy."
}