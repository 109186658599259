export default {
	"主Key（勿动, 红色自动填充的为重复项，不可有）":"罗马尼亚Romania(ro)",
	"Alert_PageOneContent":"Veți avea 2 profiluri disponibile în total pentru scenarii diferite, cum ar fi o întâlnire sau alte perioade ale zilei pentru experiențe optime.",
	"Alert_PageTwoContent1":"Atingeți pentru a edita numele profilului",
	"Alert_PageTwoContent2":"Atingeți pictogramele de sub „Repetare” pentru a seta zilele efective ale acestui profil, atingeți din nou pentru a de-selecta.",
	"Alert_PageThreeContent1":"„Toată ziua” înseamnă că setările dvs. vor fi efective pe parcursul unei zile.",
	"Alert_PageThreeContent2":"Odată dezactivate, alertele pot fi modificate în funcție de zi și de noapte",
	"Alert_PageThreeContent3":"Atingeți săgeata „>” pentru a modifica perioada de alertă pentru zi și noapte.",
	"Alert_PageFourContent1":"Puteți dezactiva Alerta scăzută urgentă cu ajutorul comutatoarelor.",
	"Alert_PageFourContent2":"Nivelul de alertă nu poate fi modificat. Aplicația vă va notifica atunci când valorile glicemiei sunt mai mici decât setările.",
	"Alert_PageFourContent3":"Atunci când există o perioadă relativ lungă de timp în care se desfășoară un eveniment Urgent Low, aplicația vă va notifica după intervalele setate.",
	"Alert_PageFourContent4":"Puteți personaliza sunetele de alertă cu diferite tonuri de apel sau vibrații.",
	"Alert_PageFiveContent1":"Puteți dezactiva alerta de nivel scăzut cu ajutorul comutatorului.",
	"Alert_PageFiveContent2":"Nivelul de alertă poate fi modificat; aplicația vă va notifica atunci când citirile glicemiei sunt mai mici decât setările.",
	"Alert_PageFiveContent3":"Puteți seta intervalul de alertă. Atunci când există o perioadă relativ lungă de desfășurare a unui eveniment scăzut, aplicația vă va notifica la intervalele setate.",
	"Alert_PageFiveContent4":"Puteți personaliza sunetele de alertă cu diferite tonuri de apel sau vibrații.",
	"Alert_PageSixContent1":"Puteți dezactiva alerta ridicată cu ajutorul comutatorului.",
	"Alert_PageSixContent2":"Nivelul de alertă poate fi modificat; aplicația vă va notifica atunci când citirile glicemiei sunt mai mari decât setările.",
	"Alert_PageSixContent3":"Ați putea seta intervalul pentru alerte. Atunci când există o perioadă relativ lungă de desfășurare a unui eveniment ridicat, aplicația vă va notifica la intervalele stabilite.",
	"Alert_PageSixContent4":"Puteți amâna manual prima alertă ridicată pentru o anumită perioadă. Dacă nivelul dvs. de glucoză rămâne în intervalul de alertă ridicată; notificările de alertă se vor relua. De exemplu, aplicația va declanșa alertele la 20 de minute după primul eveniment ridicat.",
	"Alert_PageSixContent5":"De asemenea, puteți personaliza sunetele de alertă cu diferite tonuri de apel sau vibrații.",
	"Alert_PageSevenContent1":"Atingeți „Adăugare profil alerte” pentru a crea o nouă setare care va intra în vigoare pe perioade diferite. Rețineți că, dacă selectați zile care se suprapun și care sunt incluse într-un alt profil, noile setări le vor suprascrie pe cele existente pentru zilele respective.",
	"Alert_PageSevenContent2":"Un nou profil este creat, puteți personaliza perioadele efective ale celor 2 profiluri.",
	"Alert_Setting_Model":"Sunete de alertă",
	"Alert_Setting_DelayFirst":"Întârzierea primei alerte înalte",
	"Alert_Setting_Mg_54":"55 mg/dL",
	"Alert_AlertTitle":"Personalizați-vă alertele de glucoză",
	"Alert_UrgentLowAlert":"Alertă scăzută urgentă",
	"Alert_LowAlert":"Alertă scăzută",
	"Alert_HighAlert":"Alertă ridicată",
	"Monday_Abb":"Luni",
	"Tuesday_Abb":"marți",
	"Wednesday_Abb":"miercuri",
	"Thursday_Abb":"joi",
	"Friday_Abb":"vineri",
	"Saturday_Abb":"sâmbătă",
	"Sunday_Abb":"duminică",
	"Alert_Setting_AllDay":"Toată ziua",
	"Alert_Setting_UrgentLow":"Alertă scăzută urgentă",
	"Alert_Setting_Low":"Alertă scăzută",
	"Alert_Setting_High":"Alertă ridicată",
	"Alert_Setting_Daytime":"Ziua (08:00-20:00)",
	"Alert_Setting_Nighttime":"Nighttime(20:00-08:00)",
	"Alert_Setting_Interval":"Interval",
	"Alert_Setting_Level":"Nivel",
	"Alert_Setting_Vibration":"Vibrație",
	"Alert_Setting_Add":"Adăugare profil alerte",
	"Alert_Setting_System":"Sistem",
	"Alert_Setting_Mg_180":"180 mg/dL",
	"Alert_Setting_Mg_70":"70 mg/dL",
	"Alert_Setting_Mmol_10":"10,0 mmol/L",
	"Alert_Setting_Mmol_3":"3,1 mmol/L",
	"Alert_Setting_Mmol_3_9":"3,9 mmol/L",
	"Alert_Setting_Default":"Implicit",
	"Alert_Setting_Default2":"Implicit2",
	"Alert_Setting_Min_5":"5 minute",
	"Alert_Setting_Min_30":"30 de minute",
	"Alert_Setting_Repeat":"Se repetă",
	"FAQ_FaqTitle1":"Ce este aplicația Syai Link?",
	"FAQ_FaqTitle2":"Care este diferența dintre Syai Link și Syai Tag?",
	"FAQ_FaqTitle3":"Ar trebui să iau decizii de tratament pe baza Syai Link App?",
	"FAQ_FaqTitle4":"Cum funcționează aplicația Syai Link?",
	"FAQ_FaqTitle5":"Datele Syai Link vor fi întârziate? Cât durează întârzierea?",
	"FAQ_FaqTitle6":"Ce notificări voi primi?",
	"FAQ_FaqTitle7":"Câți utilizatori Syai Tag pot urmări cu Syai Link App?",
	"FAQ_FaqTitle8":"Atunci când stabilesc o conexiune cu mai mulți partajatori, ale căror informații le va afișa widget-ul meu?",
	"FAQ_FaqTitle9":"Pot vedea datele istorice ale acționarului?",
	"FAQ_FaqTitle10":"Ce înseamnă „HI” și „LO”?",
	"FAQ_FaqTitle11":"Ce indică zona albastră din graficul tendințelor?",
	"FAQ_FaqTitle12":"Ce legătură are intervalul țintă cu alertele privind glicemia?",
	"FAQ_FaqTitle13":"Pot accesa alte aplicații și servicii cu contul meu Syai Link?",
	"FAQ_FaqTitle14":"Ce informații vor fi afișate în centrul de mesaje?",
	"FAQ_FaqContent1":"Syai Link App este o aplicație special concepută pentru rude, prieteni și alți utilizatori Syai Tag. Puteți vedea în timp real datele privind glicemia, curba glicemiei și starea monitorului utilizatorilor Syai Tag și puteți primi notificări în caz de glicemie anormală și alte urgențe.",
	"FAQ_FaqContent2":"Aplicația Syai Link este concepută pentru a fi utilizată de familie, prieteni și îngrijitori. Aceștia ar putea vedea datele și alertele privind glicemia în timp real pe smartphone-urile lor. \nAplicația Syai Tag este concepută pentru a fi utilizată de persoanele cu diabet pentru a efectua monitorizarea de rutină a glucozei utilizând un smartphone și un monitor Syai Tag.",
	"FAQ_FaqContent3":"Datele și analizele privind glucoza furnizate în aplicație nu trebuie utilizate ca recomandări directe de tratament. Înainte de a lua orice decizie crucială privind tratamentul, vă rugăm să efectuați alte examinări pentru o dublă verificare, cum ar fi testele pe degete, pentru o administrare optimă a tratamentului.",
	"FAQ_FaqContent4":"Atunci când Syai Tag App primește date privind glucoza de la monitor, transmite datele privind glucoza către server. Aplicația Syai Tag App permite utilizatorului să invite familia, prietenii și îngrijitorii să urmărească starea glicemiei celui care o împărtășește. Acești membri ai familiei, prieteni și îngrijitori pot instala aplicația Syai Link pe telefoanele lor, pot accepta invitația de partajare și apoi pot vizualiza datele privind glicemia și pot primi alerte privind glicemia.",
	"FAQ_FaqContent5":"Latența datelor va fi cauzată de diverși factori, cum ar fi decalajul rețelei, conexiunile întrerupte și așa mai departe. În condiții relativ ideale, întârzierea ar trebui să fie mai mică de câteva secunde.",
	"FAQ_FaqContent6":"a. Atunci când valoarea glicemiei pacientului este mai mare/mai mare/mai mică decât nivelurile de alertă stabilite, cum ar fi nivelurile de glicemie ridicată, scăzută și scăzută urgentă.\nb. Când nivelul de glucoză al pacientului se află în afara intervalelor prestabilite, adică sub %n sau peste %r.\nc. Atunci când persoana care partajează a încheiat sesiunea.\nd. Atunci când contul de partajare este șters.\ne. Când utilizatorul a încetat partajarea.\nf. Când există mesaje necitite.\ng. Când există invitații noi.",
	"FAQ_FaqContent7":"Un cont Syai Link poate urmări până la 10 utilizatori Syai Tag.\nUn cont Syai Tag poate invita până la 10 utilizatori Syai Link.",
	"FAQ_FaqContent8":"Din cauza limitărilor în funcționalitatea widget-urilor, putem afișa doar informațiile primului distribuitor de pe lista dvs. Puteți utiliza funcția „Pin on top” din pagina de setări pentru a prioritiza sharer-ul preferat.",
	"FAQ_FaqContent9":"Datele în timp real și datele din ultimele 24 de ore pot fi vizualizate de follower, dar nu și datele anterioare. În plus, puteți vedea datele numai după acceptarea invitației, datele anterioare acestei date nu pot fi vizualizate.",
	"FAQ_FaqContent10":"„HI„ indică o valoare a glucozei mai mare decât %r.\n”LO” indică o valoare a glucozei mai mică decât %n.",
	"FAQ_FaqContent11":"Aceasta reprezintă intervalul țintă setat de partajator în aplicația sa Syai Tag și nu puteți modifica această setare.",
	"FAQ_FaqContent12":"Cele două sunt independente și nu se afectează reciproc. Intervalul țintă va fi afișat numai în graficul de tendințe, setat de partajator în Syai Tag App, și nu poate fi modificat în Syai Link App. \nAlerta de glucoză este o notificare care vă este trimisă atunci când nivelul de glucoză al sharer-ului depășește intervalul normal. Aceasta poate fi modificată în pagina Setări.",
	"FAQ_FaqContent13":"Contul dvs. Syai Link este de fapt un cont Syai, puteți accesa toate aplicațiile și serviciile pentru uz neprofesional, cum ar fi aplicația Syai Tag.",
	"FAQ_FaqContent14":"Toate notificările pe care le primiți vor fi afișate în centrul de mesaje. De asemenea, puteți verifica următoarele mesaje de făcut în centrul de mesaje:\na. Invitații în așteptare.\nb. Când există o nouă actualizare disponibilă.\nc. Alerte de glicemie pentru evenimente de nivel ridicat/scăzut/scăzut urgent."
}