export default {
	"主Key（勿动, 红色自动填充的为重复项，不可有）":"希腊Greek (el)",
	"Alert_PageOneContent":"Θα έχετε διαθέσιμα 2 προφίλ συνολικά, για διαφορετικά σενάρια, όπως συσκέψεις και άλλες περιόδους μέσα στην ημέρα για βέλτιστη εμπειρία.",
	"Alert_PageTwoContent1":"Πατήστε για να επεξεργαστείτε το όνομα προφίλ",
	"Alert_PageTwoContent2":"Πατήστε κάτω, στο εικονίδιο \"Επανάληψη\" για να ορίσετε τις ημέρες ισχύος του προφίλ και, πατήστε ξανά για να αποεπιλέξετε.",
	"Alert_PageThreeContent1":"\"Ολοήμερο\" σημαίνει ότι οι ρυθμίσεις σας ισχύουν για όλη την ημέρα. ",
	"Alert_PageThreeContent2":"Αφού απενεργοποιηθούν, οι Ειδοποιήσεις μπορούν να τροποποιηθούν για την ημέρα και τη νύχτα.",
	"Alert_PageThreeContent3":"Πατήστε στο \">\" βέλος για να αλλάξετε τα διαστήματα ημέρας και νύχτας.",
	"Alert_PageFourContent1":"Μπορείτε να απενεργοποιήσετε την Ειδοποίηση Επείγοντος Χαμηλού με τον διακόπτη.",
	"Alert_PageFourContent2":"Το όριο της ειδοποίησης Επείγοντος Χαμηλού δεν μπορεί να τροποποιηθεί. Η εφαρμογή θα σας ενημερώσει όταν οι τιμές γλυκόζεις βρίσκονται κάτω από τις ρυθμίσεις.",
	"Alert_PageFourContent3":"Όταν συμβεί μία μακρά περίοδος εξέλιξης Επείγοντος Χαμηλού, η εφαρμογή θα σας ειδοποιεί κατά τα ορισμένα χρονικά διαστήματα.",
	"Alert_PageFourContent4":"Μπορείτε να προσαρμόσετε τους ήχους ειδοποιήσεων με διαφορετικά μοτίβα ή με δόνηση.",
	"Alert_PageFiveContent1":"Μπορείτε να απενεργοποιήσετε την Ειδοποίηση Χαμηλού με τον διακόπτη.",
	"Alert_PageFiveContent2":"Το όριο της Ειδοποίησης μπορεί να αλλάξει. Η εφαρμογή θα σας ειδοποιήσει όταν οι τιμές σας βρίσκονται κάτω από τις ρυθμίσεις.",
	"Alert_PageFiveContent3":"Μπορείτε να θέσετε τα διαστήματα των Ειδοποιήσεων.",
	"Alert_PageFiveContent4":"Μπορείτε να προσαρμόσετε τους ήχους Ειδοποιήσεων με διαφορετικά μοτίβα ή με δόνηση.",
	"Alert_PageSixContent1":"Μπορείτε να απενεργοποιήσετε την Ειδοποίηση Υψηλού με τους διακόπτες.",
	"Alert_PageSixContent2":"Το όριο της Ειδοποίησης μπορεί να αλλάξει. Η εφαρμογή θα σας ειδοποιήσει όταν οι τιμές σας βρίσκονται πάνω από τις ρυθμίσεις.",
	"Alert_PageSixContent3":"Μπορείτε να θέσετε τα διαστήματα των Ειδοποιήσεων. Όταν συμβεί μία μακρά περίοδος εξέλιξης Επείγοντος Χαμηλού, η εφαρμογή θα σας ειδοποιεί κατά τα ορισμένα χρονικά διαστήματα.",
	"Alert_PageSixContent4":"Μπορείτε να καθυστερήσετε χειροκίνητα την Πρώτη Ειδοποίηση Υψηλού για συγκεκριμένο χρόνο. Εάν η γλυκόζη σας παραμείνει υψηλή, η ειδοποίηση θα ενεργοποιηθεί. Για παράδειγμα, η εφαρμογή θα σας ενημερώσει στα 20 λεπτά από την πρώτη υψηλή μέτρηση.",
	"Alert_PageSixContent5":"Μπορείτε να προσαρμόσετε τους ήχους Ειδοποιήσεων με διαφορετικά μοτίβα ή με δόνηση.",
	"Alert_PageSevenContent1":"Πατήστε \"Προσθήκη προφίλ Ειδοποίησης\" για να δημιουργήσετε μία νέα ρύθμιση που θα έχει ισχύ σε δια φορετικά χρονικά διαστήματα. Σημειώστε ότι εάν επιλέξετε επικαλύπτόμενα με άλλη ρύθμιση διαστήματα, η νέα ρύθμιση θα υπερκαλύψει την υπάρχουσα για τις σχετικές ημέρες.",
	"Alert_PageSevenContent2":"Ένα νέο προφίλ δημιουργήθηκε, μπορείτε να προσαρμόσετε τα διαστήματα και των 2 προφίλ.",
	"Alert_Setting_Model":"Ήχοι Ειδοποίησης",
	"Alert_Setting_DelayFirst":"Καθυστέρηση Πρώτης Ειδοποίησης Υψηλού ",
	"Alert_Setting_Mg_54":"55 mg/dl",
	"Alert_AlertTitle":"Προσαρμόστε τις ειδοποιήσεις γλυκόζης",
	"Alert_UrgentLowAlert":"Ειδοποίηση Επείγοντος Χαμηλού",
	"Alert_LowAlert":"Ειδοποίηση Χαμηλού",
	"Alert_HighAlert":"Ειδοποίηση Επείγοντος Υψηλού",
	"Monday_Abb":"Δευ",
	"Tuesday_Abb":"Τρι",
	"Wednesday_Abb":"Τετ",
	"Thursday_Abb":"Πεμ",
	"Friday_Abb":"Παρ",
	"Saturday_Abb":"Σαβ",
	"Sunday_Abb":"Κυρ",
	"Alert_Setting_AllDay":"Ολοήμερη",
	"Alert_Setting_UrgentLow":"Ειδοποίηση Επείγοντος Χαμηλού",
	"Alert_Setting_Low":"Ειδοποίηση Χαμηλού",
	"Alert_Setting_High":"Ειδοποίηση Υψηλού",
	"Alert_Setting_Daytime":"Ημέρα(08:00-20:00)",
	"Alert_Setting_Nighttime":"Νύχτα(20:00-08:00)",
	"Alert_Setting_Interval":"Διάστημα",
	"Alert_Setting_Level":"Όριο",
	"Alert_Setting_Vibration":"Δόνηση",
	"Alert_Setting_Add":"Προσθέστε Προφίλ Ειδοποίησης",
	"Alert_Setting_System":"Σύστημα",
	"Alert_Setting_Mg_180":"180 mg/dL",
	"Alert_Setting_Mg_70":"70 mg/dL",
	"Alert_Setting_Mmol_10":"10.0 mmol/L",
	"Alert_Setting_Mmol_3":"3.1 mmol/L",
	"Alert_Setting_Mmol_3_9":"3.9 mmol/L",
	"Alert_Setting_Default":"Προκαθορισμένη",
	"Alert_Setting_Default2":"Προκαθορισμένη 2",
	"Alert_Setting_Min_5":"5 λεπτά",
	"Alert_Setting_Min_30":"30 λεπτά",
	"Alert_Setting_Repeat":"Επανάληψη",
	"FAQ_FaqTitle1":"Τι είναι η εφαρμογή Syai Link",
	"FAQ_FaqTitle2":"Ποια είναι η διαφορά με ταξύ της εφαρμογής Syai Lnk και της Syai Tag;",
	"FAQ_FaqTitle3":"Μπορώ να προβώ σε θεραπευτικές αποφάσεις με βάση την εφαρμογή Syai Tag;",
	"FAQ_FaqTitle4":"Πως λειτουργεί η εφαρμογή Syai Tag;",
	"FAQ_FaqTitle5":"Υπάρχουν καθυστερήσεις στις τιμές γλυκόζης που λαμβάνω στην εφαρμογή Syai Lnk;",
	"FAQ_FaqTitle6":"Τι είδους γνωστοποιήσεις θα λαμβάνω;",
	"FAQ_FaqTitle7":"Πόσους χρήστες Syai Tag μπορώ να ακολουθώ μέσω της εφαρμογής Syai Lnk;",
	"FAQ_FaqTitle8":"Όταν πραγματοποιώ κοινόχρηστες συνδέσεις με πολλούς χρήστες, ποιανού οι πληροφορίες θα εμφανίζονται στην επιφάνεια εργασίας μου;",
	"FAQ_FaqTitle9":"Μπορώ να βλέπω τα κοινόχρηστα ιστορικά δεδομένα;",
	"FAQ_FaqTitle10":"Τι σημαίνουν οι ενδείξεις \"ΥΨΗΛΟ\" και \"ΧΑΜΗΛΟ\";",
	"FAQ_FaqTitle11":"Τι απεικονίζει η μπλέ περιοχή στο γράφημα της τάσης της γλυκόζης;",
	"FAQ_FaqTitle12":"Ποια είναι η σχέση μεταξύ του εύρους στόχου και της ειδοποίησης γλυκόζης;",
	"FAQ_FaqTitle13":"Μπορώ να συνδεθώ ταυτόχρονα με τον ίδιο λογαριασμό στις εφαρμογές Syai Tag και syai Lnk;",
	"FAQ_FaqTitle14":"Τι είδους πληροφορίες απεικονίζονται στο κέντρο μηνυμάτων;",
	"FAQ_FaqContent1":"Με την εφαρμογή Syai Lnk, οι χρήστες του Syai Tag μπορούν να μοιράζονται απομακρυσμένα τις μετρήσεις γλυκόζης τους με έως και 10 μέλη της οικογένειας ή με φίλους. Ο κάθε χρήστης Syai Lnk θα λαμβάνει αυτόματα ειδοποιήσεις και μετρήσεις γλυκόζης με βάση τα επίπεδα αυτής.",
	"FAQ_FaqContent2":"Η εφαρμογή Syai Lnk έχει σχεδιαστεί για χρήση από την οικογένεια, τους φίλους και τους φροντιστές ή τους ιατρούς. Όλοι αυτοί θα μπορούν να δουν δεδομένα γλυκόζης καθώς και ειδοποιήσεις σε πραγματικό χρόνο στα smartphone τους. \nΗ εφαρμογή Syai Tag έχει σχεδιαστεί για χρήση από άτομα με διαβήτη για τη διενέργεια τακτικής παρακολούθησης της γλυκόζης χρησιμοποιώντας ένα smartphone και έναν Syai Tag Αισθητήρα.",
	"FAQ_FaqContent3":"Τα δεδομένα γλυκόζης και οι αναλύσεις που παρέχονται εντός της εφαρμογής δεν θα πρέπει να χρησιμοποιούνται ως απευθείας συμβουλές θεραπείας. Πριν λάβετε οποιαδήποτε κρίσιμη απόφαση, παρακαλώ ελέγξτε ξανά με μέτρηση από το δάχτυλο, για βέλτιστη θεραπεία.",
	"FAQ_FaqContent4":"Όταν η εφαρμογή Syai Tag λαμβάνει δεδομένα γλυκόζης από τον Αισθητήρα, τα μεταδίδει στον διακομιστή. Η εφαρμογή Syai Tag επιτρέπει στο χρήστη να προσκαλεί την οικογένεια, τους φίλους και τους φροντιστές να λάβουν δεδομένα γλυκόζης. Αυτοί, η οικογένεια, οι φίλοι και οι φροντιστές μπορούν να εγκαταστήσουν την εφαρμογή Syai Lnk στα τηλέφωνά τους, να αποδεχτούν την πρόσκληση κοινής χρήσης και, στη συνέχεια, να δουν δεδομένα γλυκόζης και να λάβουν ειδοποιήσεις γλυκόζης.",
	"FAQ_FaqContent5":"Η καθυστέρηση δεδομένων μπορεί να προκληθεί από διάφορους παράγοντες, συμπεριλαμβανομένης της συνδεσιμότητας δικτύου, όταν το Syai Tag ανεβάζει δεδομένα και όταν η εφαρμογή Syai Lnk μεταφέρει δεδομένα. Σε σχετικά ιδανικές συνθήκες δικτύου, ο χρόνος καθυστέρησης είναι συνήθως λίγα δευτερόλεπτα.",
	"FAQ_FaqContent6":"α. Όταν το επίπεδο γλυκόζης του χρήστη υπερβαίνει/βρίσκεται στο/κάτω από το καθορισμένο εύρος υπενθύμισης, όπως στα υψηλά, στα χαμηλά και στα επείγοντα χαμηλά επίπεδα γλυκόζης.\nb. Όταν το επίπεδο γλυκόζης του διαμοιραστή είναι εκτός προκαθορισμένων ορίων, δηλαδή κάτω από %n ή πάνω από %r.\nc. Όταν ο χρήστης ολοκλήρωσε την περίοδο του/της.\nd. Όταν ο λογαριασμός κοινής χρήσης έχει διαγραφεί.\ne. Όταν ο χρήστης σταμάτησε την κοινή χρήση.\nστ. Όταν υπάρχουν μη αναγνωσμένα μηνύματα.\n Όταν υπάρχουν νέες προσκλήσεις.",
	"FAQ_FaqContent7":"Ένας λογαριασμός Syai Lnk μπορεί να παρακολουθεί έως και 15 χρήστες Syai Tag.\nΈνας λογαριασμός Syai Tag μπορεί να προσκαλέσει έως και 10 χρήστες Syai Link.",
	"FAQ_FaqContent8":"Λόγω περιορισμών στη λειτουργικότητα του γραφικού στοιχείου, μπορούμε να εμφανίσουμε μόνο τις πληροφορίες του πρώτου χρήστη στη λίστα σας. Μπορείτε να χρησιμοποιήσετε τη λειτουργία \"Καρφίτσωμα στην Κορυφή\" στη σελίδα ρυθμίσεων για να δώσετε προτεραιότητα στον προτιμώμενο διαμοιραστή.",
	"FAQ_FaqContent9":"Στον ακόλουθο μπορούν να προβληθούν τα δεδομένα σε πραγματικό χρόνο και τα δεδομένα των τελευταίων 24 ωρών, αλλά όχι τα δεδομένα πριν. Επιπλέον, μπορείτε να δείτε τα δεδομένα μόνο μετά την αποδοχή της πρόσκλησης, τα δεδομένα πριν από αυτό δεν μπορούν να προβληθούν.",
	"FAQ_FaqContent10":"Η ένδειξη \"ΥΨΗΛΟ\" σηματοδοτεί μία μέτρηση γλυκόζης πάνω από %. Η ένδειξη \"ΧΑΜΗΛΟ\" μία ένδειξη κάτω από %",
	"FAQ_FaqContent11":"Αυτό αντιπροσωπεύει το εύρος στόχου που έχει οριστεί από τον διαμοιραστή στην εφαρμογή Syai Tag και δεν μπορείτε να τροποποιήσετε αυτήν τη ρύθμιση.",
	"FAQ_FaqContent12":"Τα δύο αυτά είναι ανεξάρτητα και δεν επηρεάζουν το ένα το άλλο. Το εύρος στόχου θα εμφανίζεται μόνο στο γράφημα τάσεων, που έχει οριστεί από τον διαμοιραστή στην εφαρμογή Syai Tag και δεν μπορεί να τροποποιηθεί στην εφαρμογή Syai Lnk. \nΗ ειδοποίηση γλυκόζης είναι μια ειδοποίηση που αποστέλλεται σε εσάς όταν το επίπεδο γλυκόζης του χρήστη που μοιράζεται υπερβαίνει το φυσιολογικό εύρος. Αυτό μπορεί να τροποποιηθεί στη σελίδα Ρυθμίσεις.",
	"FAQ_FaqContent13":"Ναί. Ο λογαριασμός σας Syai μπορεί να συνδεθεί σε όλες τις εφαρμογές της Syai χωρίς διπλότυπες εγγραφές.",
	"FAQ_FaqContent14":"Όλες οι ειδοποιήσεις που λαμβάνετε θα εμφανίζονται στο κέντρο μηνυμάτων. Μπορείτε επίσης να επεξεργαστείτε τα ακόλουθα μηνύματα υποχρεώσεων στο κέντρο μηνυμάτων:\na. Προσκλήσεις σε εκκρεμότητα\nb. Όταν υπάρχει διαθέσιμη ενημέρωση νέας έκδοσης.\nc. Ειδοποιήσεις για Υψηλά/Χαμηλά?Επείγοντα Χαμηλά συμβάντα."
}