export default {
	"主Key（勿动, 红色自动填充的为重复项，不可有）":"西班牙Spanish(es)",
	"Alert_PageOneContent":"Tendrá 2 perfiles disponibles en total para diferentes escenarios, como una reunión u otros periodos del día para experiencias óptimas.",
	"Alert_PageTwoContent1":"Pulse para editar el nombre del perfil",
	"Alert_PageTwoContent2":"Pulse sobre los iconos debajo de «Repetir» para establecer los días efectivos de este perfil, pulse de nuevo para anular la selección.",
	"Alert_PageThreeContent1":"«Todo el día» significa que su configuración será efectiva durante todo el día.",
	"Alert_PageThreeContent2":"Una vez desactivado, las Alertas pueden modificarse por día y noche",
	"Alert_PageThreeContent3":"Pulse sobre la flecha «>» para modificar el periodo de alerta diurna y nocturna.",
	"Alert_PageFourContent1":"Puede desactivar la Alerta Baja Urgente con los conmutadores.",
	"Alert_PageFourContent2":"El nivel de Alerta no se puede modificar. La App le notificará cuando las lecturas de glucosa sean inferiores a las establecidas.",
	"Alert_PageFourContent3":"Cuando hay un período relativamente largo de un evento de Urgente Baja en curso, la App le notificará siguiendo los intervalos establecidos.",
	"Alert_PageFourContent4":"Puedes personalizar los sonidos de alerta con diferentes tonos de llamada o vibración.",
	"Alert_PageFiveContent1":"Puedes desactivar la Alerta Baja con el conmutador.",
	"Alert_PageFiveContent2":"El nivel de Alerta puede cambiarse; la App le notificará cuando las lecturas de glucosa sean inferiores a las establecidas.",
	"Alert_PageFiveContent3":"Puedes configurar el intervalo de las Alertas. Cuando hay un período relativamente largo de un evento bajo en curso, la App le notificará en los intervalos establecidos.",
	"Alert_PageFiveContent4":"Puedes personalizar los Sonidos de Alerta con diferentes tonos de llamada o vibración.",
	"Alert_PageSixContent1":"Puedes desactivar la Alerta Alta con el conmutador.",
	"Alert_PageSixContent2":"El nivel de Alerta puede cambiarse; la App le notificará cuando las lecturas de glucosa sean superiores a las establecidas.",
	"Alert_PageSixContent3":"Puedes establecer el intervalo para las Alertas. Cuando haya un periodo relativamente largo de un evento alto en curso, la app te notificará en los intervalos establecidos.",
	"Alert_PageSixContent4":"Puedes retrasar manualmente la primera Alerta Alta durante un periodo determinado. Si su nivel de glucosa permanece en el intervalo de alerta alta, se reanudarán las notificaciones de alerta. Por ejemplo, la aplicación activará las alertas 20 minutos después del primer evento alto.",
	"Alert_PageSixContent5":"También puedes personalizar los sonidos de alerta con diferentes tonos de llamada o vibración.",
	"Alert_PageSevenContent1":"Pulse «Añadir perfil de alertas» para crear un nuevo ajuste que tendrá efecto en diferentes periodos. Tenga en cuenta que si selecciona días que se solapan y que están incluidos en otro perfil, la nueva configuración sobrescribirá la existente para los días correspondientes.",
	"Alert_PageSevenContent2":"Se crea un nuevo perfil, puede personalizar los periodos efectivos de los 2 perfiles.",
	"Alert_Setting_Model":"Sonidos de Alerta",
	"Alert_Setting_DelayFirst":"Retraso Primera Alerta Alta",
	"Alert_Setting_Mg_54":"55 mg/dL",
	"Alert_AlertTitle":"Personalice sus alertas de glucosa",
	"Alert_UrgentLowAlert":"Alerta baja urgente",
	"Alert_LowAlert":"Alerta baja",
	"Alert_HighAlert":"Alerta alta",
	"Monday_Abb":"Lun",
	"Tuesday_Abb":"Mar",
	"Wednesday_Abb":"Mié",
	"Thursday_Abb":"Jue",
	"Friday_Abb":"Vie",
	"Saturday_Abb":"Sáb",
	"Sunday_Abb":"Dom",
	"Alert_Setting_AllDay":"Todo el día",
	"Alert_Setting_UrgentLow":"Alerta Baja Urgente",
	"Alert_Setting_Low":"Alerta baja",
	"Alert_Setting_High":"Alerta Alta",
	"Alert_Setting_Daytime":"Día(08:00-20:00)",
	"Alert_Setting_Nighttime":"Nighttime(20:00-08:00)",
	"Alert_Setting_Interval":"Intervalo",
	"Alert_Setting_Level":"Nivel",
	"Alert_Setting_Vibration":"Vibración",
	"Alert_Setting_Add":"Añadir perfil de alertas",
	"Alert_Setting_System":"Sistema",
	"Alert_Setting_Mg_180":"180 mg/dL",
	"Alert_Setting_Mg_70":"70 mg/dL",
	"Alert_Setting_Mmol_10":"10,0 mmol/L",
	"Alert_Setting_Mmol_3":"3,1 mmol/L",
	"Alert_Setting_Mmol_3_9":"3,9 mmol/L",
	"Alert_Setting_Default":"Por defecto",
	"Alert_Setting_Default2":"Por defecto2",
	"Alert_Setting_Min_5":"5 minutos",
	"Alert_Setting_Min_30":"30 minutos",
	"Alert_Setting_Repeat":"Repetir",
	"FAQ_FaqTitle1":"¿Qué es la aplicación Syai Link?",
	"FAQ_FaqTitle2":"¿Cuál es la diferencia entre Syai Link y Syai Tag?",
	"FAQ_FaqTitle3":"¿Debo tomar decisiones de tratamiento basándome en la aplicación Syai Link?",
	"FAQ_FaqTitle4":"¿Cómo funciona la aplicación Syai Link?",
	"FAQ_FaqTitle5":"¿Se retrasan los datos de Syai Link? ¿Cuánto dura el retraso?",
	"FAQ_FaqTitle6":"¿Qué notificaciones recibiré?",
	"FAQ_FaqTitle7":"¿A cuántos usuarios de Syai Tag puedo seguir con la App Syai Link?",
	"FAQ_FaqTitle8":"Cuando establezco una conexión con varios compartidores, ¿la información de quién se mostrará en mi widget?",
	"FAQ_FaqTitle9":"¿Puedo ver los datos históricos del compartidor?",
	"FAQ_FaqTitle10":"¿Qué significa «HI» y «LO»?",
	"FAQ_FaqTitle11":"¿Qué indica la zona azul en el gráfico de tendencias?",
	"FAQ_FaqTitle12":"¿Qué tiene que ver el rango objetivo con las alertas de glucemia?",
	"FAQ_FaqTitle13":"¿Puedo acceder a otras aplicaciones y servicios con mi cuenta Syai Link?",
	"FAQ_FaqTitle14":"¿Qué información se mostrará en el centro de mensajes?",
	"FAQ_FaqContent1":"Syai Link App es una aplicación especialmente diseñada para familiares, amigos y otros usuarios de Syai Tag. Puede ver los datos de glucemia en tiempo real, la curva de glucemia y el estado del monitor de los usuarios de Syai Tag, y recibir notificaciones en caso de glucemia anormal y otras emergencias.",
	"FAQ_FaqContent2":"La aplicación Syai Link está diseñada para ser utilizada por familiares, amigos y cuidadores. Podrán ver los datos de glucosa en tiempo real y las alertas en sus smartphones. \nLa aplicación Syai Tag está diseñada para que las personas con diabetes realicen un seguimiento rutinario de la glucosa utilizando un smartphone y un medidor Syai Tag.",
	"FAQ_FaqContent3":"Los datos y análisis de glucosa proporcionados en la aplicación no deben utilizarse como referencias directas para el tratamiento. Antes de tomar cualquier decisión crucial sobre el tratamiento, por favor, realice otros exámenes de doble comprobación, como pruebas de punción digital, para una administración óptima del tratamiento.",
	"FAQ_FaqContent4":"Cuando la aplicación Syai Tag recibe los datos de glucosa del medidor, los transmite al servidor. La aplicación Syai Tag permite al usuario invitar a familiares, amigos y cuidadores a realizar un seguimiento del estado de glucosa del usuario. Estos familiares, amigos y cuidadores pueden instalar la aplicación Syai Link en sus teléfonos, aceptar la invitación para compartir y, a continuación, ver los datos de glucosa y recibir alertas de glucosa.",
	"FAQ_FaqContent5":"La latencia de los datos estará causada por diversos factores, como el retardo de la red, las interrupciones de la conexión, etc. En condiciones relativamente ideales, el retraso debería ser inferior a unos segundos.",
	"FAQ_FaqContent6":"a. Cuando la lectura de glucosa del paciente está por encima/por debajo de los niveles de alerta establecidos, como niveles de glucosa Alto, Bajo y Urgentemente Bajo.\nb. Cuando el nivel de glucosa del compartidor está fuera de los rangos preestablecidos, es decir, por debajo de %n o por encima de %r.\nc. Cuando el compartidor ha finalizado su sesión.\nd. Cuando la cuenta del compartidor es eliminada.\ne. Cuando el usuario ha dejado de compartir.\nf. Cuando hay mensajes no leídos. Cuando hay nuevas invitaciones.",
	"FAQ_FaqContent7":"Una cuenta Syai Link puede seguir hasta 10 usuarios Syai Tag.\nUna cuenta Syai Tag puede invitar hasta 10 usuarios Syai Link.",
	"FAQ_FaqContent8":"Debido a limitaciones en la funcionalidad del widget, sólo podemos mostrar la información del primer compartidor de tu lista. Puedes utilizar la función «Anclar arriba» en la página de configuración para dar prioridad al compartidor preferido.",
	"FAQ_FaqContent9":"El seguidor puede ver los datos en tiempo real y los de las últimas 24 horas, pero no los anteriores. Además, sólo se pueden ver los datos después de aceptar la invitación, los datos anteriores a esta fecha no se pueden ver.",
	"FAQ_FaqContent10":"«HI» indica una lectura de glucosa superior a %r.\n “LO” indica una lectura de glucosa inferior a %n.",
	"FAQ_FaqContent11":"Esto representa el rango objetivo establecido por el compartidor en su App Syai Tag, y usted no puede modificar esta configuración.",
	"FAQ_FaqContent12":"Ambos son independientes y no se afectan mutuamente. El intervalo objetivo sólo se mostrará en el gráfico de tendencias, establecido por el usuario en la aplicación Syai Tag, y no se puede modificar en la aplicación Syai Link. \nLa alerta de glucosa es una notificación que se le envía cuando el nivel de glucosa del usuario supera el rango normal. Esto puede modificarse en la página de Configuración.",
	"FAQ_FaqContent13":"Tu cuenta Syai Link es en realidad una cuenta Syai, puedes acceder a todas las Apps y Servicios de uso no profesional como la App Syai Tag.",
	"FAQ_FaqContent14":"Todas las notificaciones que recibas se mostrarán en el centro de mensajes. También puede comprobar los siguientes mensajes pendientes en el centro de mensajes:\na. Invitaciones pendientes.\nb. Cuando haya una nueva actualización disponible.\nc. Alertas de glucosa para eventos Alto/Bajo/Bajo Urgente."
}