export default {
	"主Key（勿动, 红色自动填充的为重复项，不可有）":"意大利Italian(it)",
	"Alert_PageOneContent":"Avrai a disposizione 2 profili per scenari diversi, come una riunione o altri momenti della giornata, per una migliore esperienza.",
	"Alert_PageTwoContent1":"Premi per modificare il nome del profilo",
	"Alert_PageTwoContent2":"Premi le icone sotto \"Ripeti\" per impostare i giorni effettivi di questo profilo, premi di nuovo per deselezionare.",
	"Alert_PageThreeContent1":"Tutto il giorno significa che le tue impostazioni saranno efficaci per tutto il giorno.",
	"Alert_PageThreeContent2":"Una volta disattivati, gli avvisi possono essere modificati per il giorno e la notte",
	"Alert_PageThreeContent3":"Premi la freccia \">\" per modificare il periodo di avviso per il giorno e la notte.",
	"Alert_PageFourContent1":"Puoi disattivare l'avviso di ipoglicemia grave con i pulsanti.",
	"Alert_PageFourContent2":"Il livello di avviso non può essere modificato. L'app ti avviserà quando le letture del glucosio sono inferiori alle impostazioni.",
	"Alert_PageFourContent3":"Quando è in corso un periodo relativamente lungo di un evento di ipoglicemia grave, l'app ti avviserà seguendo gli intervalli impostati.",
	"Alert_PageFourContent4":"Puoi personalizzare i suoni di avviso con diverse suonerie o vibrazioni.",
	"Alert_PageFiveContent1":"Puoi disattivare l'avviso di ipoglicemia con il pulsante.",
	"Alert_PageFiveContent2":"Il livello di avviso può essere modificato; l'app ti avviserà quando le letture del glucosio sono inferiori alle impostazioni.",
	"Alert_PageFiveContent3":"Puoi impostare l'intervallo degli avvisi. Quando c'è un periodo relativamente lungo di un evento basso in corso, l'app ti avviserà agli intervalli impostati.",
	"Alert_PageFiveContent4":"Puoi personalizzare i suoni di avviso con diverse suonerie o vibrazioni.",
	"Alert_PageSixContent1":"Puoi disattivare l'avviso valore alto premendo il cursore.",
	"Alert_PageSixContent2":"Il livello di avviso può essere modificato; l'app ti avviserà quando le letture del glucosio sono superiori agli intervalli impostati.",
	"Alert_PageSixContent3":"Puoi impostare l'intervallo per gli avvisi. Quando c'è un periodo relativamente lungo di un evento alto in corso, l'app ti avviserà agli intervalli impostati.",
	"Alert_PageSixContent4":"Puoi ritardare manualmente il tuo primo avviso di valore alto per un determinato periodo. Se il tuo livello di glucosio rimane nell'intervallo di avviso alto, le notifiche di avviso riprenderanno. Ad esempio, l'app farà scattare gli avvisi 20 minuti dopo il primo evento alto.",
	"Alert_PageSixContent5":"Puoi personalizzare anche i suoni di avviso con diverse suonerie o vibrazioni.",
	"Alert_PageSevenContent1":"Tocca \"Aggiungi profilo avvisi\" per creare una nuova impostazione che avrà effetto su periodi diversi. Se selezioni giorni sovrapposti inclusi in un altro profilo, le nuove impostazioni sovrascriveranno quelle esistenti per i giorni rilevanti.",
	"Alert_PageSevenContent2":"Viene creato un nuovo profilo, puoi personalizzare i periodi effettivi dei 2 profili.",
	"Alert_Setting_Model":"Suoni di avviso",
	"Alert_Setting_DelayFirst":"Ritardo primo avviso di valore alto",
	"Alert_Setting_Mg_54":"55 mg/dL",
	"Alert_AlertTitle":"Personalizza gli avvisi sul tuo glucosio",
	"Alert_UrgentLowAlert":"Allarme valore gravemente basso",
	"Alert_LowAlert":"Allarme valore basso",
	"Alert_HighAlert":"Allarme valore alto",
	"Monday_Abb":"Lun",
	"Tuesday_Abb":"Mar",
	"Wednesday_Abb":"Mer",
	"Thursday_Abb":"Gio",
	"Friday_Abb":"Ven",
	"Saturday_Abb":"Sab",
	"Sunday_Abb":"Dom",
	"Alert_Setting_AllDay":"Tutto il giorno",
	"Alert_Setting_UrgentLow":"Allarme valore gravemente basso",
	"Alert_Setting_Low":"Allarme valore basso",
	"Alert_Setting_High":"Allarme valore alto",
	"Alert_Setting_Daytime":"Giorno(08:00-20:00)",
	"Alert_Setting_Nighttime":"Notte(20:00-08:00)",
	"Alert_Setting_Interval":"Intervallo",
	"Alert_Setting_Level":"Livello",
	"Alert_Setting_Vibration":"Vibrazione",
	"Alert_Setting_Add":"Aggiungi Profilo di avvisi",
	"Alert_Setting_System":"Sistema",
	"Alert_Setting_Mg_180":"180 mg/dL",
	"Alert_Setting_Mg_70":"70 mg/dL",
	"Alert_Setting_Mmol_10":"10.0 mmol/L",
	"Alert_Setting_Mmol_3":"3.1 mmol/L",
	"Alert_Setting_Mmol_3_9":"3.9 mmol/L",
	"Alert_Setting_Default":"Predefinito",
	"Alert_Setting_Default2":"Predefinito2",
	"Alert_Setting_Min_5":"5 minuti",
	"Alert_Setting_Min_30":"30 minuti",
	"Alert_Setting_Repeat":"Ripeti",
	"FAQ_FaqTitle1":"Cos'è l'app Syai Link?",
	"FAQ_FaqTitle2":"Qual è la differenza tra Syai Link e Syai Tag?",
	"FAQ_FaqTitle3":"Dovrei prendere decisioni terapeutiche in base all'app Syai Link?",
	"FAQ_FaqTitle4":"Come funziona l'app Syai Link?",
	"FAQ_FaqTitle5":"I dati di Syai Link subiranno ritardi? Di quanto è il ritardo?",
	"FAQ_FaqTitle6":"Quali notifiche riceverò?",
	"FAQ_FaqTitle7":"Quanti utenti Syai Tag posso seguire con l'app Syai Link?",
	"FAQ_FaqTitle8":"Quando stabilisco una connessione con più condivisori, quali informazioni verranno visualizzate nel mio widget?",
	"FAQ_FaqTitle9":"Posso vedere i dati storici del condivisore?",
	"FAQ_FaqTitle10":"Cosa significano \"HI\" e \"LO\"?",
	"FAQ_FaqTitle11":"Cosa indica l'area blu nel grafico di tendenza?",
	"FAQ_FaqTitle12":"Cosa c'entra l'intervallo target con gli avvisi di zucchero nel sangue?",
	"FAQ_FaqTitle13":"Posso accedere ad altre app o servizi con il mio account Syai Link?",
	"FAQ_FaqTitle14":"Quali informazioni verranno visualizzate nel centro messaggi?",
	"FAQ_FaqContent1":"L'app Syai Link è un'app appositamente progettata per parenti, amici e altri utenti Syai Tag. Puoi visualizzare i dati sulla glicemia in tempo reale, la curva della glicemia e monitorare lo stato degli utenti di Syai Tag, ricevendo notifiche in caso di glicemia anomala e altre emergenze.",
	"FAQ_FaqContent2":"L'app Syai Link è progettata per essere utilizzata da familiari, amici e altri caregiver. E' possibile visualizzare dati e avvisi sul glucosio in tempo reale sul proprio smartphone. \nL'app Syai Tag è progettata per essere utilizzata dalle persone con diabete per eseguire il monitoraggio sistematico del glucosio utilizzando uno smartphone e un sensore Syai Tag.",
	"FAQ_FaqContent3":"I dati e le analisi sul glucosio forniti nell'app non devono essere utilizzati come riferimenti diretti al trattamento. Prima di prendere qualsiasi decisione cruciale sul trattamento, si prega di sottoporsi ad altri esami per ricontrollare, come i test tramite polpastrello, per una somministrazione ottimale del trattamento.",
	"FAQ_FaqContent4":"Quando l'app Syai Tag riceve i dati sul glucosio dal sensore, i dati sul glucosio vengono trasmessi al server. L'app Syai Tag consente all'utente di invitare familiari, amici e altri caregiver a tenere traccia dello stato del glucosio di chi condivide. Questi familiari e amici possono installare l'app Syai Link sui propri telefoni, accettare l'invito alla condivisione, quindi visualizzare i dati sul glucosio e ricevere avvisi sul glucosio.",
	"FAQ_FaqContent5":"La latenza dei dati sarà causata da vari fattori, come ritardo di rete, connessioni interrotte e così via. In condizioni relativamente ideali, il ritardo dovrebbe essere inferiore a pochi secondi.",
	"FAQ_FaqContent6":"a. Quando il livello di glucosio dell'utente condivisore supera l'intervallo preimpostato, ad esempio livelli di glucosio alto, basso e gravemente basso.\nb. Quando il livello di glucosio dell'utente condivisore è fuori dagli intervalli preimpostati, ovvero inferiore a %n o superiore a %r.\nc. Quando l'utente condivisore ha terminato la sessione.\nd. Quando l'account condivisore viene eliminato.\ne. Quando l'utente condivisore ha interrotto la condivisione.\nf. Quando sono presenti messaggi non letti.\ng. Quando ci sono nuovi inviti.",
	"FAQ_FaqContent7":"Un account Syai Link può seguire fino a 10 utenti Syai Tag.\nUn account Syai Tag può invitare fino a 10 utenti Syai Link.",
	"FAQ_FaqContent8":"A causa delle limitazioni nella funzionalità del widget, possiamo visualizzare solo le informazioni del primo condivisore del tuo elenco. Puoi utilizzare la funzione \"Blocca in alto\" nella pagina delle impostazioni per dare priorità al condivisore preferito.",
	"FAQ_FaqContent9":"Il follower può visualizzare i dati in tempo reale e quelli delle ultime 24 ore, ma non i dati precedenti. Inoltre, potrai vedere i dati solo dopo aver accettato l'invito, i dati precedenti a questa data non possono essere visualizzati.",
	"FAQ_FaqContent10":"HI indica una lettura del glucosio superiore a %r.\n\"LO\" indica una lettura del glucosio inferiore a %n.",
	"FAQ_FaqContent11":"Questo mostra l'intervallo target impostato dall'utente condivisore nella propria app Syai Tag, perciò non è possibile modificare questa impostazione.",
	"FAQ_FaqContent12":"I due sono indipendenti e non si influenzano a vicenda. L'intervallo target verrà visualizzato solo nel grafico di tendenza, impostato dal condivisore nell'app Syai Tag e non potrà essere modificato nell'app Syai Link. \nL'avviso glucosio è una notifica che ti viene inviata quando il livello di glucosio del condivisore supera l'intervallo normale. Questo può essere modificato nella pagina Impostazioni.",
	"FAQ_FaqContent13":"Il tuo account Syai Link è un account Syai, quindi potrai accedere a tutte le app e i servizi per uso non professionale come l'app Syai Tag.",
	"FAQ_FaqContent14":"Tutte le notifiche ricevute verranno visualizzate nel centro messaggi. Puoi anche controllare i seguenti messaggi di cose da fare nel centro messaggi:\na. Informazioni sull'invito non elaborate\nb. Quando è disponibile un nuovo aggiornamento di versione dell'app.\nc. Avvisi di glucosio per eventi alti/bassi/gravemente bassi."
}