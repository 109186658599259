export default {
	"主Key（勿动, 红色自动填充的为重复项，不可有）":"匈牙利Hungarian (hu)",
	"Alert_PageOneContent":"Összesen 2 profil áll rendelkezésére a különböző forgatókönyvekhez, például egy találkozóhoz vagy a nap más időszakaihoz az optimális élmény érdekében.",
	"Alert_PageTwoContent1":"Koppintson a profilnév szerkesztéséhez",
	"Alert_PageTwoContent2":"Érintse meg az ikonokat az „Ismétlés” alatt a profil érvényes napjainak beállításához, érintse meg újra a kijelölés megszüntetéséhez.",
	"Alert_PageThreeContent1":"Az „Egész nap” azt jelenti, hogy a beállítások a nap folyamán érvényesek lesznek.",
	"Alert_PageThreeContent2":"Kikapcsolás után a riasztások módosíthatók nappali és éjszakai idő szerint",
	"Alert_PageThreeContent3":"Érintse meg a \">\" nyilat a nappali és éjszakai riasztási időszak módosításához.",
	"Alert_PageFourContent1":"Az Urgent Low Alert a kapcsolókkal kikapcsolható.",
	"Alert_PageFourContent2":"A riasztási szint nem módosítható. Az alkalmazás értesíti Önt, ha a glükóz értékek alacsonyabbak a beállításoknál.",
	"Alert_PageFourContent3":"Ha viszonylag hosszú ideig tart egy Sürgős alacsony esemény, az alkalmazás a beállított időközönként értesíti Önt.",
	"Alert_PageFourContent4":"Testreszabhatja a figyelmeztető hangokat különböző csengőhangokkal vagy rezgéssel.",
	"Alert_PageFiveContent1":"Az alacsony riasztást a kapcsolóval kapcsolhatja ki.",
	"Alert_PageFiveContent2":"A riasztási szint módosítható; az alkalmazás értesíti Önt, ha a glükózérték alacsonyabb a beállításoknál.",
	"Alert_PageFiveContent3":"Beállíthatja a riasztások időközét. Ha viszonylag hosszú ideig van folyamatban alacsony esemény, az alkalmazás a beállított időközönként értesíti Önt.",
	"Alert_PageFiveContent4":"Testreszabhatja a figyelmeztető hangokat különböző csengőhangokkal vagy rezgéssel.",
	"Alert_PageSixContent1":"A magas riasztást a kapcsolóval kapcsolhatja ki.",
	"Alert_PageSixContent2":"A riasztási szint módosítható; az alkalmazás értesíti Önt, ha a glükóz értékek magasabbak a beállításoknál.",
	"Alert_PageSixContent3":"Beállíthatja a riasztások intervallumát. Ha viszonylag hosszú időszak van folyamatban egy magas eseményről, az alkalmazás a beállított időközönként értesíti Önt.",
	"Alert_PageSixContent4":"Az első magas riasztást manuálisan elhalaszthatja egy bizonyos ideig. Ha glükózszintje a magas riasztási tartományban marad; a riasztási értesítések folytatódnak. Például az alkalmazás 20 perccel az első magas esemény után figyelmeztetést ad ki.",
	"Alert_PageSixContent5":"Testreszabhatja a figyelmeztető hangokat különböző csengőhangokkal vagy rezgéssel is.",
	"Alert_PageSevenContent1":"Érintse meg a „Figyelmeztetési profil hozzáadása” lehetőséget egy új beállítás létrehozásához, amely különböző időszakokban lép érvénybe. Vegye figyelembe, hogy ha olyan átfedő napokat választ ki, amelyek egy másik profilban szerepelnek, az új beállítások felülírják a meglévőt a megfelelő napokra vonatkozóan.",
	"Alert_PageSevenContent2":"Új profil jön létre, a 2 profil érvényességi idejét személyre szabhatja.",
	"Alert_Setting_Model":"Figyelmeztető hangok",
	"Alert_Setting_DelayFirst":"Az első magas riasztás késleltetése",
	"Alert_Setting_Mg_54":"55 mg/dl",
	"Alert_AlertTitle":"Szabja testre glükóz-figyelmeztetéseit",
	"Alert_UrgentLowAlert":"Sürgős alacsony riasztás",
	"Alert_LowAlert":"Alacsony riasztás",
	"Alert_HighAlert":"Magas riasztás",
	"Monday_Abb":"Hé",
	"Tuesday_Abb":"Kedd",
	"Wednesday_Abb":"Sze",
	"Thursday_Abb":"Csüt",
	"Friday_Abb":"Pé",
	"Saturday_Abb":"Szo",
	"Sunday_Abb":"Vas",
	"Alert_Setting_AllDay":"Egész nap",
	"Alert_Setting_UrgentLow":"Sürgős alacsony riasztás",
	"Alert_Setting_Low":"Alacsony riasztás",
	"Alert_Setting_High":"Magas riasztás",
	"Alert_Setting_Daytime":"Nappal (08:00-20:00)",
	"Alert_Setting_Nighttime":"Éjszaka (20:00-08:00)",
	"Alert_Setting_Interval":"Intervallum",
	"Alert_Setting_Level":"Szint",
	"Alert_Setting_Vibration":"Rezgés",
	"Alert_Setting_Add":"Figyelmeztetési profil hozzáadása",
	"Alert_Setting_System":"Rendszer",
	"Alert_Setting_Mg_180":"180 mg/dl",
	"Alert_Setting_Mg_70":"70 mg/dl",
	"Alert_Setting_Mmol_10":"10,0 mmol/L",
	"Alert_Setting_Mmol_3":"3,1 mmol/l",
	"Alert_Setting_Mmol_3_9":"3,9 mmol/l",
	"Alert_Setting_Default":"Alapértelmezett",
	"Alert_Setting_Default2":"Alapértelmezett 2",
	"Alert_Setting_Min_5":"5 perc",
	"Alert_Setting_Min_30":"30 perc",
	"Alert_Setting_Repeat":"Ismétlés",
	"FAQ_FaqTitle1":"Mi az a Syai Link alkalmazás?",
	"FAQ_FaqTitle2":"Mi a különbség a Syai Link és a Syai Tag között?",
	"FAQ_FaqTitle3":"A kezeléssel kapcsolatos döntéseket a Syai Link App alapján kell meghoznom?",
	"FAQ_FaqTitle4":"Hogyan működik a Syai Link alkalmazás?",
	"FAQ_FaqTitle5":"A Syai Link adatai késni fognak? Mennyi a késés?",
	"FAQ_FaqTitle6":"Milyen értesítéseket kapok?",
	"FAQ_FaqTitle7":"Hány Syai Tag felhasználót követhetek a Syai Link alkalmazással?",
	"FAQ_FaqTitle8":"Ha több megosztóval létesítek kapcsolatot, kinek az adatai jelennek meg a widgetemben?",
	"FAQ_FaqTitle9":"Megnézhetem a megosztó előzményadatait?",
	"FAQ_FaqTitle10":"Mit jelent a \"HI\" és \"LO\"?",
	"FAQ_FaqTitle11":"Mit jelez a kék terület a trendgrafikonon?",
	"FAQ_FaqTitle12":"Mi köze van a céltartománynak a vércukorszint-figyelmeztetésekhez?",
	"FAQ_FaqTitle13":"Hozzáférhetek más alkalmazásokhoz és szolgáltatásokhoz a Syai Link fiókommal?",
	"FAQ_FaqTitle14":"Milyen információk jelennek meg az üzenetközpontban?",
	"FAQ_FaqContent1":"A Syai Link App egy kifejezetten rokonok, barátok és más Syai Tag felhasználók számára készült alkalmazás. Megtekintheti a valós idejű vércukoradatokat, a vércukor görbét és a Syai Tag felhasználók állapotát, valamint értesítéseket kaphat rendellenes vércukorszintről és egyéb vészhelyzetekről.",
	"FAQ_FaqContent2":"A Syai Link alkalmazást családtagok, barátok és gondozók általi használatra tervezték. Valós idejű glükózadatokat és riasztásokat láthattak okostelefonjaikon. \nA Syai Tag alkalmazást cukorbetegek számára tervezték, hogy rutinszerű glükózmonitorozást végezzenek okostelefonon és Syai Tag Monitoron.",
	"FAQ_FaqContent3":"Az alkalmazáson belül biztosított glükózadatok és elemzések nem használhatók közvetlen kezelési ajánlásként. Mielőtt bármilyen fontos kezelési döntést hozna, végezzen további vizsgálatokat, például ujjbegy-tesztet az optimális kezelés érdekében.",
	"FAQ_FaqContent4":"Amikor a Syai Tag App glükózadatokat kap a monitortól, továbbítja a glükózadatokat a szervernek. A Syai Tag App lehetővé teszi a felhasználó számára, hogy meghívja családját, barátait és gondozóit, hogy nyomon kövesse a megosztó glükóz állapotát. Ezek a családtagok, barátok és gondozók telepíthetik a Syai Link alkalmazást telefonjukra, elfogadhatják a megosztási meghívást, majd megtekinthetik a glükózadatokat és kaphatnak glükózértesítéseket.",
	"FAQ_FaqContent5":"Az adatok késleltetését különböző tényezők okozhatják, mint például a hálózati késés, a megszakadt kapcsolatok és így tovább. Viszonylag ideális körülmények között a késleltetésnek kevesebbnek kell lennie néhány másodpercnél.",
	"FAQ_FaqContent6":"a. Ha a páciens glükózszintje a beállított riasztási szintek felett/alatt van, például magas, alacsony és sürgős alacsony glükózszint.\nb. Ha a megosztó glükózszintje kívül esik az előre beállított tartományokon, azaz %n alatt vagy %r felett van.\nc. Amikor a megosztó befejezte a munkamenetét.\nd. Amikor a megosztói fiókot törölték.\ne. Amikor a megosztó leállította a megosztást.\nf. Ha vannak olvasatlan üzenetek.\ng. Amikor új meghívók vannak.",
	"FAQ_FaqContent7":"Egy Syai Link-fiók legfeljebb 10 Syai Tag-felhasználót követhet.\nA Syai Tag-fiók legfeljebb 10 Syai Link-felhasználót hívhat meg.",
	"FAQ_FaqContent8":"A widgetek korlátai miatt csak a listán szereplő első megosztó adatait tudjuk megjeleníteni. Használhatja a \"Rögzítés a tetején\" funkciót a beállítások oldalon az előnyben részesített megosztó prioritásainak megadásához.",
	"FAQ_FaqContent9":"A valós idejű és az elmúlt 24 óra adatait a követő megtekintheti, a korábbi adatokat azonban nem. Ezen kívül csak a meghívó elfogadása után láthatja az adatokat, az ezen időpont előtti adatokat nem.",
	"FAQ_FaqContent10":"A „HI” azt jelzi, hogy a glükózszint magasabb, mint %r.\nA „LO” azt jelzi, hogy a glükózérték alacsonyabb, mint %n.",
	"FAQ_FaqContent11":"Ez a megosztó által a Syai Tag alkalmazásban beállított céltartományt jelenti, és ez a beállítás nem módosítható.",
	"FAQ_FaqContent12":"A kettő független és nincs hatással egymásra. A céltartomány csak a trenddiagramon jelenik meg, amelyet a megosztó állított be a Syai Tag alkalmazásban, és nem módosítható a Syai Link alkalmazásban. \nA glükózriasztás egy értesítés, amelyet akkor küldenek Önnek, ha a megosztó glükózszintje meghaladja a normál tartományt. Ez a Beállítások oldalon módosítható.",
	"FAQ_FaqContent13":"Az Ön Syai Link-fiókja valójában egy Syai-fiók, így hozzáférhet az összes alkalmazáshoz és szolgáltatáshoz nem professzionális használatra, például a Syai Tag alkalmazáshoz.",
	"FAQ_FaqContent14":"Minden kapott értesítés megjelenik az üzenetközpontban. A következő teendőket is ellenőrizheti az üzenetközpontban:\na. Függőben lévő meghívók.\nb. Amikor új frissítés érhető el.\nc. Glükóz figyelmeztetések magas/alacsony/sürgős alacsony események esetén."
}