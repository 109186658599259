// index.js
import { createI18n } from "vue-i18n";
import en from "./en";
import it from "./it";
import cs from "./cs";
import zh from "./zh";
import ru from "./ru";
import sk from "./sk";
import hu from "./hu";
import ro from "./ro";
import el from "./el";
import si from "./si";
import es from "./es";


const messages = {
  cs,
  en,
  it,
  zh,
  ru,
  sk,
  hu,
  ro,
  el,
  si,
  es,
};
const language = (navigator.language || "en").toLocaleLowerCase(); // 这是获取浏览器的语言
console.log(language);
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: localStorage.getItem("lang") || language.split("-")[0] || "en", // 首先从缓存里拿，没有的话就用浏览器语言，
  fallbackLocale: "en", // 设置备用语言
  messages,
});

export default i18n;
